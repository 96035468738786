import {
  FC,
  ReactElement,
  PropsWithChildren,
  useRef,
  useState,
  useEffect,
  createContext,
} from "react";

import DBService from "../services/DatabaseService";

import IImageData from "../types/imageData";
import IImagesData from "../types/imagesData";

interface DbContextInterface {
  isFetching: boolean;
  imagesData: IImagesData;
  currentImageData: IImageData;
  nextImage(): void;
  addImageData(imageData: IImageData): Promise<boolean>;
  updateImageData(id: string, imageData: IImageData): Promise<boolean>;
  removeImageData(id: string): Promise<boolean>;
  resetUnseenImages(): Promise<void>;
}

const DbContext = createContext<DbContextInterface>(null!);

const getRandomItem = (array: any[]): any => {
  return array[Math.floor(Math.random() * array.length)];
};

export const DbContextProvider: FC<PropsWithChildren> = (
  props
): ReactElement => {
  const [isFetching, setIsFetching] = useState(false);
  const [imagesData, setImagesData] = useState<IImagesData>({});

  const [currentId, setCurrentId] = useState("");
  const unseenIdsRef = useRef<string[]>([]);

  useEffect(() => {
    const mount = async (): Promise<void> => {
      const newImagesData = await syncImagesData();
      unseenIdsRef.current = Object.keys(newImagesData);
      nextImage();
    };

    mount();
  }, []);

  const resetUnseenImages = async (): Promise<void> => {
    const newImagesData = await syncImagesData();
    unseenIdsRef.current = Object.keys(newImagesData);
    nextImage();
  };

  const nextImage = (): void => {
    const randomId = getRandomItem(unseenIdsRef.current);
    setCurrentId(randomId);
    unseenIdsRef.current = unseenIdsRef.current.filter(id => id !== randomId);
  };

  const syncImagesData = async (): Promise<IImagesData> => {
    setIsFetching(true);
    const newImagesData = await DBService.fetchImagesDataFromDB();
    setIsFetching(false);
    setImagesData(newImagesData);
    return newImagesData;
  };

  const removeImageData = async (id: string): Promise<boolean> => {
    try {
      await DBService.removeImageDataInDB(id);
    } catch (err) {
      return false;
    }
    await syncImagesData();
    return true;
  };

  const updateImageData = async (
    id: string,
    imageData: IImageData
  ): Promise<boolean> => {

    await DBService.updateImageDataInDB(id, imageData);
    await syncImagesData();
    return true;
  };

  const addImageData = async (imageData: IImageData): Promise<boolean> => {
    try {
      const key = await DBService.addImageDataInDB(imageData);
      if (key === null) {
        return false;
      }
    } catch (error) {
      return false;
    }

    await syncImagesData();
    return true;
  };

  return (
    <DbContext.Provider
      value={{
        nextImage,
        isFetching,
        imagesData,
        addImageData,
        updateImageData,
        removeImageData,
        resetUnseenImages,
        currentImageData: imagesData[currentId],
      }}
    >
      {props.children}
    </DbContext.Provider>
  );
};

export default DbContext;
