import { FC, PropsWithChildren, ReactElement } from "react";
import Footer from "./Footer";

import Header from "./Header";

import styles from "./Layout.module.css";

const Layout: FC<PropsWithChildren> = (props): ReactElement => {
  return <>
    <Header />
    <main className={styles.main}>{props.children}</main>
    <Footer />
  </>
};
export default Layout;