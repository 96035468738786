import React from "react";

import styles from "./SearchBar.module.css";

interface Props {
  onChange(e: React.ChangeEvent): void;
}

const SearchBar = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  return (
    <input
      ref={ref}
      className={`centered ${styles.search}`}
      type="text"
      placeholder="Search by Name 🔍"
      onChange={props.onChange}
    />
  );
});

export default SearchBar;
