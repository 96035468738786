import React, { FC, ReactElement, useState, useRef } from "react";

import styles from "./AnswerForm.module.css";

interface Props {
  onSubmit: Function;
}

const AnswerForm: FC<Props> = (props: Props): ReactElement => {
  const answerInputRef = useRef<HTMLInputElement>(null);
  const [answer, setAnswer] = useState("");

  const inputChangeHandler = (e: React.FormEvent<HTMLInputElement>): void => {
    e.preventDefault();
    setAnswer(answerInputRef.current!.value);
  };

  const submitFormHandler = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    props.onSubmit(answer);
    setAnswer("");
  };

  return (
    <form className={`centered ${styles.form}`} onSubmit={submitFormHandler}>
      <label htmlFor="answer">Put Your Answer Below ⬇️</label>
      <div className={styles.control}>
        <input
          type="text"
          id="answer"
          placeholder="your answer 📝"
          ref={answerInputRef}
          value={answer}
          onChange={inputChangeHandler}
          required
        />
        <button>
          <i className="fa-solid fa-check"></i>
        </button>
      </div>
    </form>
  );
};

export default AnswerForm;
