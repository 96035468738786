import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";

import HomePage from "./pages/HomePage";
import SignInPage from "./pages/SignInPage";
import NewQuestionPage from "./pages/NewQuestionPage";
import ImagesPage from "./pages/ImagesPage";

import { AuthContextProvider } from "./contexts/AuthContext";
import { DbContextProvider } from "./contexts/DbContext";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route index element={<HomePage />} />
      <Route path="/signin" element={<SignInPage />} />
      <Route path="/images" element={<ImagesPage />} />
      <Route path="/new-question" element={<NewQuestionPage />} />
    </Route>
  )
);

function App() {
  return (
    <DbContextProvider>
      <AuthContextProvider>
        <RouterProvider router={router} />
      </AuthContextProvider>
    </DbContextProvider>
  );
}

export default App;
