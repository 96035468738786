import { FC, ReactElement, useState, useEffect } from "react";

import LoadingSpinner from "./LoadingSpinner";

import IImageData from "../../types/imageData";

import styles from "./ImageContainer.module.css";

interface Props {
  imageData: IImageData;
  onLoad?(): void;
  onError?(): void;
}

const ImageContainer: FC<Props> = (props: Props): ReactElement => {
  const [isLoading, setIsLoading] = useState(false);

  const imageData = props.imageData;

  useEffect(() => {
    setIsLoading(true);
  }, [imageData.url]);

  const loadImageHandler = () => {
    setIsLoading(false);

    if (props.onLoad !== undefined) {
      props.onLoad();
    }
  };

  const errorHandler = () => {
    setIsLoading(false);

    if (props.onError !== undefined) {
      props.onError();
    }
  };

  return (
    <div className={`centered ${styles.container}`}>
      <LoadingSpinner show={isLoading} />
      <img
        style={{ display: isLoading ? "none" : "block" }}
        src={imageData.url}
        alt={imageData.name}
        onLoad={loadImageHandler}
        onError={errorHandler}
      />
    </div>
  );
};

export default ImageContainer;
