import { NextFn, User, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { auth } from "../firebase";

class AuthService {
  addAuthChangeListener(observer: NextFn<User | null>): void {
    auth.onAuthStateChanged(observer);
  }

  signIn(email: string, password: string) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  signOut() {
    return signOut(auth);
  }
}

export default new AuthService();
