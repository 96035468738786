import { FC, ReactElement, useContext, useState } from "react";

import { Navigate } from "react-router-dom";

import AuthContext from "../contexts/AuthContext";
import DbContext from "../contexts/DbContext";

import ErrorPrompt from "../components/Alert/ErrorPrompt";
import SuccessPrompt from "../components/Alert/SuccessPrompt";
import NewQuestionForm from "../components/Admin/NewQuestionForm";
import Layout from "../layouts/Layout";

const NewQuestionPage: FC<{}> = (): ReactElement => {
  const dbCtx = useContext(DbContext);
  const authCtx = useContext(AuthContext);

  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const dismissHandler = (): void => {
    setShowError(false);
    setShowSuccess(false);
  };

  const submitHandler = (name: string, url: string): void => {
    dbCtx.addImageData({ name, url }).then((success: boolean): void => {
      if (success) {
        setShowSuccess(true);
      } else {
        setShowError(true);
      }
    });
  };

  if (!authCtx.isSignedIn) {
    return <Navigate to="/signin" replace />;
  }

  return (
    <Layout>
      <ErrorPrompt show={showError} onDismiss={dismissHandler} />
      <SuccessPrompt
        show={showSuccess}
        text="Successfully added a new parasite"
        onDismiss={dismissHandler}
      />
      <section>
        <NewQuestionForm onSubmit={submitHandler} />
      </section>
    </Layout>
  );
};

export default NewQuestionPage;
