import { FC, ReactElement, useState, useRef, useContext } from "react";

import DbContext from "../../contexts/DbContext";

import DeletePrompt from "./DeletePrompt";
import ImageItemControl from "./ImageItemControl";
import ImageContainer from "../UI/ImageContainer";
import IImageData from "../../types/imageData";

import styles from "./ImageItem.module.css";

interface Props {
  imageData: IImageData;
  id: string;
}

const ImageItem: FC<Props> = (props): ReactElement => {
  const dbCtx = useContext(DbContext);

  const inputRef = useRef<HTMLInputElement>(null);
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);

  const imageData = props.imageData;

  const updateImageData = () => {
    dbCtx.updateImageData(props.id, {
      name: inputRef.current!.value,
      url: imageData.url,
    });
  };

  const removeImageData = () => {
    dbCtx.removeImageData(props.id);
    setShowDeletePrompt(false);
  };

  return (
    <div className={styles.item}>
      <DeletePrompt
        show={showDeletePrompt}
        onConfirm={removeImageData}
        onDismiss={() => {
          setShowDeletePrompt(false);
        }}
      />
      <div className={styles.name}>
        <ImageItemControl
          ref={inputRef}
          name={imageData.name}
          onUpdate={updateImageData}
          onRemove={() => {
            setShowDeletePrompt(true);
          }}
        />
      </div>
      <ImageContainer
        imageData={imageData}
        onLoad={() => {}}
        onError={() => {}}
      />
    </div>
  );
};

export default ImageItem;
