import { useContext } from "react";

import DbContext from "../../contexts/DbContext";

import styles from "./ResetPrompt.module.css";

const ResetPrompt = () => {
  const dbCtx = useContext(DbContext);

  return (
    <div className={`centered ${styles.container}`}>
      <p>You have successfully completed every single parasite!</p>
      <button onClick={dbCtx.resetUnseenImages}>Reset</button>
    </div>
  );
};

export default ResetPrompt;
