import React, { useRef, useState, useImperativeHandle } from "react";

import styles from "./SourcesControl.module.css";

interface ISource {
  id: string;
  label: string;
  placeholder: string;
  getUrl(input: string): string;
}

const SOURCES: { [name: string]: ISource } = {
  url: {
    id: "url",
    label: "URL",
    placeholder: "https://...",
    getUrl: (input: string): string => input,
  },
  googleDriveID: {
    id: "google-drive-id",
    label: "Google Drive ID",
    placeholder: "google drive id...",
    getUrl: (input: string): string =>
      `https://drive.google.com/uc?export=view&id=${input}`,
  },
  googleDriveLink: {
    id: "google-drive-link",
    label: "Google Drive Link",
    placeholder: "https://...",
    getUrl: (input: string): string => {
      const parts = input.split("/");
      const id = parts[parts.length - 2];
      return `https://drive.google.com/uc?export=view&id=${id}`;
    },
  },
};

interface Props {
  className?: string;
}

export interface SourcesControlHandle {
  getUrl(): string;
  resetInput(): void;
}

// TODO: create another component for dropdown
const SourcesControl = React.forwardRef<SourcesControlHandle, Props>(
  (props, ref) => {
    const sourceInputRef = useRef<HTMLInputElement>(null);
    const [source, setSource] = useState("googleDriveLink");

    const dropDownOptions = Object.entries(SOURCES).map(([key, value]) => (
      <option key={key} value={key}>
        {value.label}
      </option>
    ));

    const getUrl = (): string => {
      return SOURCES[source].getUrl(sourceInputRef.current!.value);
    };

    const resetInput = (): void => {
      sourceInputRef.current!.value = "";
    };

    const optionChangedHandler = (
      e: React.ChangeEvent<HTMLSelectElement>
    ): void => {
      setSource(e.target.value);
    };

    useImperativeHandle(ref, () => {
      return {
        getUrl,
        resetInput,
      };
    });

    return (
      <div className={props.className}>
        <select
          defaultValue={source}
          className={`centered ${styles.dropdown}`}
          onChange={optionChangedHandler}
        >
          {dropDownOptions}
        </select>
        <input
          type="text"
          id={SOURCES[source].id}
          ref={sourceInputRef}
          placeholder={SOURCES[source].placeholder}
          required
        />
      </div>
    );
  }
);

export default SourcesControl;
