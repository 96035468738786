import { FC, ReactElement, useState, useContext } from "react";

import { Navigate } from "react-router-dom";

import AuthContext from "../contexts/AuthContext";

import Layout from "../layouts/Layout";
import SignInForm from "../components/Auth/SignInForm";
import ErrorPrompt from "../components/Alert/ErrorPrompt";

const mapAuthCodeToMessage = (authCode: string): string => {
  switch (authCode) {
    case "auth/user-not-found":
      return "Your email is invalid";

    case "auth/wrong-password":
      return "Your password is incorrect";

    case "auth/too-many-requests":
      return "You've sent too many requests";

    default:
      console.log("authCode ", authCode);
      return "";
  }
};

const SignInPage: FC<{}> = (): ReactElement => {
  const authCtx = useContext(AuthContext);

  const [errorText, setErrorText] = useState<string>("");
  const [showError, setShowError] = useState(false);

  const dismissHandler = (): void => {
    setShowError(false);
  };

  const signInHandler = (email: string, password: string): void => {
    // use ! to note that it is impossible to be null
    authCtx.signInUser(email, password).catch((error) => {
      setErrorText(mapAuthCodeToMessage(error.code));
      setShowError(true);
    });
  };

  if (authCtx.isSignedIn) {
    return <Navigate to="/new-question" replace />;
  }

  return (
    <Layout>
      <ErrorPrompt
        show={showError}
        text={errorText}
        onDismiss={dismissHandler}
      />
      <section>
        <SignInForm onSubmit={signInHandler} />
      </section>
    </Layout>
  );
};

export default SignInPage;
