import { FC } from "react";

export interface IconProps {
	className: string;
}

const Icon: FC<IconProps> = props => {
  return <i className={props.className}></i>;
};

export default Icon;
