import { FC, ReactElement, useState, useContext } from "react";

import Layout from "../layouts/Layout";
import AnswerForm from "../components/Home/AnswerForm";
import ResetPrompt from "../components/Home/ResetPrompt";
import ImageContainer from "../components/UI/ImageContainer";
import LoadingSpinner from "../components/UI/LoadingSpinner";
import ErrorPrompt from "../components/Alert/ErrorPrompt";
import SuccessPrompt from "../components/Alert/SuccessPrompt";

import DbContext from "../contexts/DbContext";

import styles from "./HomePage.module.css";

const HomePage: FC<{}> = (): ReactElement => {
  const dbCtx = useContext(DbContext);
  const imageData = dbCtx.currentImageData;

  const [showError, setShowError] = useState(false);
  const [showWrong, setShowWrong] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const checkAnswer = (userAnswer: string): void => {
    if (imageData.name.trim() === userAnswer.trim()) {
      setShowSuccess(true);
    } else {
      setShowWrong(true);
    }
  };

  const nextQuestion = (): void => {
    dbCtx.nextImage();
    setShowWrong(false);
    setShowError(false);
    setShowSuccess(false);
  };

  const imageErrorHandler = (): void => {
    setShowError(true);
  };

  return (
    <Layout>
      <section className={styles.container}>
        <ErrorPrompt
          show={showError}
          confirmButtonText="Next"
          onDismiss={nextQuestion}
        />
        <ErrorPrompt
          show={showWrong}
          title="Wrong Answer"
          text={`The correct answer is ${imageData ? imageData.name : ""}`}
          confirmButtonText="Next"
          onDismiss={nextQuestion}
        />
        <SuccessPrompt
          show={showSuccess}
          title="Correct"
          confirmButtonText="Next"
          onDismiss={nextQuestion}
        />
        {imageData && (
          <ImageContainer imageData={imageData} onError={imageErrorHandler} />
        )}
        {!imageData && dbCtx.isFetching && (
          <LoadingSpinner
            className="centered"
            show={!imageData && dbCtx.isFetching}
            color="white"
          />
        )}
        {!imageData && !dbCtx.isFetching && <ResetPrompt />}
      </section>
      <section>
        <AnswerForm onSubmit={checkAnswer} />
      </section>
    </Layout>
  );
};

export default HomePage;
