import { FC, ReactElement, PropsWithChildren, MouseEvent } from "react";
import ReactDOM from "react-dom";

import styles from "./Modal.module.css";

interface BackdropInterface {
  onClick(e: MouseEvent): void;
}

export const Backdrop: FC<BackdropInterface> = (props): ReactElement => {
  return <div className={styles.backdrop} onClick={props.onClick} />;
};

const ModalOverlay: FC<PropsWithChildren> = (props) => {
  return (
    <div className={styles.modal}>
      <div className={styles.content}>{props.children}</div>
    </div>
  );
};

interface ModalInterface extends PropsWithChildren {
  onBackdropClick(e: MouseEvent): void;
}

const Modal: FC<ModalInterface> = (props) => {
  const portalElement = document.getElementById("overlays") as HTMLElement;

  return (
    <>
      {ReactDOM.createPortal(
        <Backdrop onClick={props.onBackdropClick} />,
        portalElement
      )}
      {ReactDOM.createPortal(
        <ModalOverlay>{props.children}</ModalOverlay>,
        portalElement
      )}
    </>
  );
};

export default Modal;
