import { useContext } from "react";

import AuthContext from "../contexts/AuthContext";

import NavBar from "../components/Header/NavBar";
import NavItem from "../components/Header/NavItem";

import styles from "./Header.module.css";
import Icon from "../components/UI/Icon";

const Header = () => {
  const authCtx = useContext(AuthContext);

  const navItems = (
    <>
      <NavItem
        route="/"
        text="Home"
        icon={<Icon className="fa-solid fa-house" />}
      />
      <NavItem
        route="/images"
        text="Images"
        icon={<Icon className="fa-solid fa-images" />}
      />
      {authCtx.isSignedIn && (
        <NavItem
          route="/new-question"
          text="Add"
          icon={<Icon className="fa-solid fa-plus" />}
          rotate
        />
      )}
      {authCtx.isSignedIn && (
        <NavItem
        route="/new-question"
        text="Settings"
        icon={<Icon className="fa-solid fa-gear" />}
        rotate
      />
      )}
      {authCtx.isSignedIn && (
        <NavItem
          route="/"
          text="Sign Out"
          icon={<Icon className="fa-solid fa-right-from-bracket" />}
          onClick={authCtx.signOutUser}
        />
      )}
      {!authCtx.isSignedIn && (
        <NavItem
          route="/signin"
          text="Sign In"
          icon={<Icon className="fa-solid fa-right-to-bracket" />}
        />
      )}
    </>
  );

  return (
    <header className={styles.header}>
      <NavBar>{navItems}</NavBar>
    </header>
  );
};

export default Header;
