import { FC, ReactElement, MouseEventHandler } from "react";
import { Link } from "react-router-dom";

import { IconProps } from "../UI/Icon";

import styles from "./NavItem.module.css";

export interface Props {
  route: string;
  icon: ReactElement<IconProps>;
  text: string;
  rotate?: boolean;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
}

const NavItem: FC<Props> = (props: Props): ReactElement => {
  const liClassName =
    styles["nav-item"] + (props.rotate ? " " + styles.rotate : " ");

  return (
    <li className={liClassName}>
      <Link to={props.route} onClick={props.onClick}>
        <span className={styles.icon}>{props.icon}</span>
        <span className={styles.text}>{props.text}</span>
      </Link>
    </li>
  );
};

export default NavItem;
