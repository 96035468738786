import { FC, ReactElement, useState } from "react";

import { Props as NavItemProps } from "./NavItem";

import { Backdrop } from "../../layouts/Modal";

import styles from "./NavBar.module.css";

interface Props {
  children: ReactElement<NavItemProps>[] | ReactElement<NavItemProps>;
}

const NavBar: FC<Props> = (props): ReactElement => {
  const [display, setDisplay] = useState(false);

  return (
    <nav className={styles.navbar}>
      {display && (
        <Backdrop
          onClick={() => {
            setDisplay(false);
          }}
        />
      )}
      <p className={styles.logo}>
        Buggy Peasy
      </p>
      <ul className={display ? styles.display : ""}>{props.children}</ul>
      <button
        className={styles.hamburger}
        onClick={() => {
          setDisplay(true);
        }}
      >
        <i className="fa fa-bars fa-2x"></i>
      </button>
    </nav>
  );
};

export default NavBar;
