import {
  FC,
  ReactElement,
  useRef,
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";

import DbContext from "../contexts/DbContext";

import Layout from "../layouts/Layout";
import SearchBar from "../components/Images/SearchBar";
import ImagesList from "../components/Images/ImagesList";
import NoResultPrompt from "../components/Images/NoResultPrompt";
import IImageData from "../types/imageData";

interface Item {
  id: string;
  imageData: IImageData;
}

const ImagesPage: FC<{}> = (): ReactElement => {
  const dbCtx = useContext(DbContext);

  const searchBarInputRef = useRef<HTMLInputElement>(null);
  const [imageItems, setImageItems] = useState<Item[]>([]);
  const [query, setQuery] = useState("");

  const filterImagesData = useCallback(
    (query: string): Item[] => {
      return Object.entries(dbCtx.imagesData)
        .map(([id, imageData]) => {
          return { id, imageData };
        })
        .filter((item) => item.imageData.name.startsWith(query));
    },
    [dbCtx.imagesData]
  );

  const updateImageItems = useCallback((): void => {
    const query = searchBarInputRef.current!.value;
    setQuery(query);
    const newImageItems = filterImagesData(query);
    setImageItems(newImageItems);
  }, [filterImagesData]);

  useEffect(() => {
    updateImageItems();
  }, [updateImageItems]);

  return (
    <Layout>
      <section>
        <SearchBar ref={searchBarInputRef} onChange={updateImageItems} />
        {query === "" && (
          <NoResultPrompt text={"Please Enter Something to See Anything"} />
        )}
        {query !== "" && imageItems.length === 0 && (
          <NoResultPrompt text={`No Result Found for "${query}"`} />
        )}
        {query !== "" && imageItems.length !== 0 && (
          <ImagesList items={imageItems} />
        )}
      </section>
    </Layout>
  );
};

export default ImagesPage;
