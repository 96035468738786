import { database } from "../firebase";
import { ref, child, get, set, push, update, remove } from "firebase/database";

import IImageData from "../types/imageData";
import IImagesData from "../types/imagesData";

const imagesDataRef = ref(database, "images");

class DBService {
  async fetchImagesDataFromDB(): Promise<IImagesData> {
    const snapshot = await get(imagesDataRef);
    if (snapshot.exists()) {
      return snapshot.val();
    } else {
      return {};
    }
  }

  async addImageDataInDB(imageData: IImageData) {
    if (!this.validateImageData(imageData)) {
      return null;
    }

    const newImageDataKey = push(imagesDataRef);
    await set(newImageDataKey, imageData);
    return newImageDataKey;
  }

  validateImageData(imageData: IImageData): boolean {
    if (imageData.name.trim() === "") {
      return false;
    }
    if (
      !(
        imageData.url.startsWith("http://") ||
        imageData.url.startsWith("https://")
      )
    ) {
      return false;
    }
    return true;
  }

  async updateImageDataInDB(id: string, imageData: IImageData): Promise<void> {
    if (!this.validateImageData(imageData)) {
      return;
    }

    const updates: IImagesData = {};
    updates[id] = imageData;
    await update(imagesDataRef, updates);
  }

  async removeImageDataInDB(id: string): Promise<void> {
    await remove(child(imagesDataRef, id));
  }
}

export default new DBService();
