import {
  FC,
  PropsWithChildren,
  useState,
  useEffect,
  createContext,
} from "react";

import AuthService from "../services/AuthService";

interface AuthContextInterface {
  isSignedIn: boolean;
  signInUser(email: string, password: string): Promise<void>;
  signOutUser(): Promise<void>;
}

const AuthContext = createContext<AuthContextInterface>(null!);

export const AuthContextProvider: FC<PropsWithChildren> = props => {
  const [isSignedIn, setIsSignedIn] = useState(false);

  useEffect(() => {
    AuthService.addAuthChangeListener(user => {
      setIsSignedIn(user !== null);
    });
  }, []);

  const signInUser = async (email: string, password: string): Promise<void> => {
    try {
      await AuthService.signIn(email, password);
    } catch (error) {
      throw error;
    }
  };

  const signOutUser = async (): Promise<void> => {
    return AuthService.signOut();
  }

  return (
    <AuthContext.Provider
      value={{
        isSignedIn,
        signInUser,
        signOutUser,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
