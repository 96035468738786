import { FC, ReactElement } from "react";

import styles from "./NoResultPrompt.module.css";

interface Props {
  text: string;
}

const NoResultPrompt: FC<Props> = (props: Props): ReactElement => {
  return (
    <div className={`centered ${styles.container}`}>
      <p> {props.text} </p>
    </div>
  );
};

export default NoResultPrompt;
