import { FC, useRef } from "react";

import SourcesDropDown, { SourcesControlHandle } from "./SourcesControl";

import styles from "../Form/Form.module.css";

interface Props {
  onSubmit(name: string, url: string): void;
}

const NewQuestionForm: FC<Props> = (props) => {
  const answerInputRef = useRef<HTMLInputElement>(null);
  const sourcesDropDownRef = useRef<SourcesControlHandle>(null);

  const submitFormHandler = (e: React.FormEvent) => {
    e.preventDefault();
    const name = answerInputRef.current!.value;
    const url = sourcesDropDownRef.current!.getUrl();
    console.log(url);
    props.onSubmit(name, url);
    clearForm();
  };

  const clearForm = () => {
    answerInputRef.current!.value = "";
    sourcesDropDownRef.current!.resetInput();
  };

  return (
    <div className={`centered ${styles.form}`}>
      <div className={styles.title}>Add A Parasite</div>
      <form onSubmit={submitFormHandler}>
        <div className={styles["form-control"]}>
          <label htmlFor="answer" className="centered">
            Answer
            <i className="fa-solid fa-lightbulb"></i>
          </label>
          <input
            type="text"
            id="answer"
            ref={answerInputRef}
            placeholder="Parasite Name"
            required
          />
        </div>
        <SourcesDropDown
          ref={sourcesDropDownRef}
          className={styles["form-control"]}
        />
        <button className="algolia-button">Submit</button>
      </form>
    </div>
  );
};

export default NewQuestionForm;
