import { FC, ReactElement } from "react";

import styles from "./Footer.module.css";

const Footer: FC = (): ReactElement => {
  return (
    <footer className={styles.footer}>
      <small>
        Copyright &copy; 2022 Hao-Ting Tso (David) All Rights Reserved.
      </small>
    </footer>
  );
};

export default Footer;
