import { FC, ReactElement } from "react";

import styles from "./LoadingSpinner.module.css";

interface Props {
  show: boolean;
  color?: string;
  className?: string;
}

const LoadingSpinner: FC<Props> = (props: Props): ReactElement => {
  const color = props.color ? props.color : "#3f3f3f";

  const style = {
    display: props.show ? "block" : "none",
    "--color": color,
  };

  return (
    <div
      className={`${props.className ? props.className : ""} ${styles.spinner}`}
      style={style}
    ></div>
  );
};

export default LoadingSpinner;
