import React, { useState, useContext } from "react";

import AuthContext from "../../contexts/AuthContext";

interface Props {
  name: string;
  onUpdate(e: React.SyntheticEvent): void;
  onRemove(e: React.SyntheticEvent): void;
}

const ImageItemControl = React.forwardRef<HTMLInputElement, Props>(
  (props, ref) => {
    const authCtx = useContext(AuthContext);

    const [isEditingName, setIsEditingName] = useState(false);

    const toggleIsEditingName = () => {
      setIsEditingName(
        (prevIsEditingName) => !prevIsEditingName && authCtx.isSignedIn
      );
    };

    const inputKeyDownHandler = (e: React.KeyboardEvent) => {
      if (e.key === "Enter") {
        confirmUpdate(e);
      } else if (e.key === "Escape" || e.key === "Esc") {
        setIsEditingName(false);
      }
    };

    const confirmUpdate = (e: React.SyntheticEvent) => {
      props.onUpdate(e);
      setIsEditingName(false);
    };

    const editControl = (
      <>
        <input
          autoFocus
          type="text"
          ref={ref}
          onKeyDown={inputKeyDownHandler}
        />
        <button onClick={confirmUpdate}>✅</button>
        <button onClick={() => setIsEditingName(false)}>❌</button>
      </>
    );

    const nonEditControl = (
      <>
        <div onClick={toggleIsEditingName}>{props.name}</div>
        {authCtx.isSignedIn && <button onClick={props.onRemove}>🗑️</button>}
      </>
    );

    return (
      <>
        {isEditingName && editControl}
        {!isEditingName && nonEditControl}
      </>
    );
  }
);

export default ImageItemControl;
