import { FC } from "react";

import ImageItem from "./ImageItem";

import IImageData from "../../types/imageData";

import styles from "./ImagesList.module.css";

interface Item {
  id: string;
  imageData: IImageData;
}

interface Props {
  items: Item[];
}

const ImagesList: FC<Props> = (props) => {
  const imageItems = props.items.map((item) => (
    <ImageItem key={item.id} id={item.id} imageData={item.imageData} />
  ));

  return <div className={styles.images}>{imageItems}</div>;
};

export default ImagesList;
