import { FC, useEffect, useCallback } from "react";

import MySwal from "../../sweetalert";

interface Props {
  title?: string;
  text?: string;
  confirmButtonText?: string;
  show: boolean;
  onDismiss: Function;
}

const SuccessPrompt: FC<Props> = (props: Props) => {
  const showAlert = useCallback(() => {
    MySwal.fire({
      title: props.title ? props.title : "Success",
      text: props.text,
      icon: "success",
      backdrop: true,
      confirmButtonText: props.confirmButtonText
        ? props.confirmButtonText
        : "Dismiss",
    }).then(() => {
      props.onDismiss();
    });
  }, [props]);

  useEffect(() => {
    if (props.show) {
      showAlert();
    }
  }, [props.show, showAlert]);

  return <></>;
};

export default SuccessPrompt;
