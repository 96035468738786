import { FC, ReactElement, useEffect, useCallback } from "react";

import MySwal from "../../sweetalert";

interface Props {
  onConfirm: Function;
  onDismiss: Function;
  show: boolean;
}

const DeletePrompt: FC<Props> = (props): ReactElement => {
  const showAlert = useCallback(() => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You will not be able to revert this!",
      icon: "warning",
      backdrop: true,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
      cancelButtonColor: "#d33",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        props.onConfirm();
      } else {
        props.onDismiss();
      }
    });
  }, [props]);

  useEffect(() => {
    if (props.show) {
      showAlert();
    }
  }, [props.show, showAlert]);

  return <></>;
};

export default DeletePrompt;
