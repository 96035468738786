import React, { FC, ReactElement, useRef } from "react";

import styles from "../Form/Form.module.css";

interface Props {
  onSubmit(email: string, password: string): void;
}

const SignInForm: FC<Props> = (props: Props): ReactElement => {
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  const validateEmail = (email: string): boolean => {
    const filter =
      /^\s*[\w\-+_]+(\.[\w\-+_]+)*@[\w\-+_]+\.[\w\-+_]+(\.[\w\-+_]+)*\s*$/;
    return String(email.trim()).search(filter) !== -1;
  };

  const validatePassword = (password: string): boolean =>
    password.trim() !== "";

  const submitFormHandler = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    const email = emailRef.current!.value;
    const password = passwordRef.current!.value;

    if (validateEmail(email) && validatePassword(password)) {
      props.onSubmit(emailRef.current!.value, passwordRef.current!.value);
    }

    clearForm();
  };

  const clearForm = (): void => {
    emailRef.current!.value = "";
    passwordRef.current!.value = "";
  };

  return (
    <div className={`centered ${styles.form}`}>
      <div className={styles.title}>Sign In Here</div>
      <form onSubmit={submitFormHandler}>
        <div className={styles["form-control"]}>
          <label htmlFor="email" className="centered">
            Email
            <i className="fa-solid fa-envelope"></i>
          </label>
          <input
            type="email"
            id="email"
            placeholder="test@test.com"
            ref={emailRef}
            required
          />
        </div>
        <div className={styles["form-control"]}>
          <label htmlFor="password" className="centered">
            Password
            <i className="fa-solid fa-key"></i>
          </label>
          <input
            type="password"
            id="password"
            placeholder="secret"
            ref={passwordRef}
            required
          />
        </div>
        <button className="algolia-button">Sign In</button>
      </form>
    </div>
  );
};

export default SignInForm;
